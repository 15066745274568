<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    dropdownMode="closeInactive"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" :to="capability.organization.id ? '/':'/install/organizations'" v-if="capability">
      <div v-if="capability.user_profile.organization_logo">
        <CImg class="custom-limit-logo-size c-sidebar-brand-full" :src="capability.user_profile.organization_logo" />
      </div>
      <div v-else>
        <CImg class="custom-limit-logo-size-none c-sidebar-brand-full" src="/edgedx-brand3.png" />
        <CImg class="custom-limit-logo-size-min c-sidebar-brand-minimized" src="/edgedx-emb.png" />
      </div>
    </CSidebarBrand>
    <CSidebarNav>
      <CSidebarNavItem v-if="myAuthorizationLevel.level <= 100 && org" to="/dashboard" :name="$t('cloud.title.dashboard')" icon="cil-speedometer" />
      <CSidebarNavItem v-if="myAuthorizationLevel.level <= 100 && org" to="/alarm" :name="$t('cloud.title.alarm')" icon="cil-bell" />
      <CSidebarNavTitle v-if="myAuthorizationLevel.level <= 100 && org">{{$t('cloud.title.subscribing_service')}}</CSidebarNavTitle>
      <CSidebarNavDropdown
        v-if="myAuthorizationLevel.level <= 100 && subscribing('retail')"
        :name="$t('cloud.title.service_retail')" icon="cil-chart-line" route="/retail"
      >
        <CSidebarNavItem v-if="myAuthorizationLevel.level <= 20" to="/retail/stores" :name="$t('cloud.title.retail_store')" />
        <CSidebarNavItem to="/retail/store-occupancy" :name="$t('cloud.title.store_occupancy')" />
        <CSidebarNavItem to="/retail/visitor-counting" :name="$t('cloud.title.visitor_counting')" />
        <CSidebarNavItem to="/retail/advanced-visitor-analysis" :name="$t('cloud.title.visitor_analysis_advanced')" />
        <CSidebarNavItem to="/retail/queue-management" :name="$t('cloud.title.queue_management')" />
        <CSidebarNavItem v-if="myAuthorizationLevel.level <= 20" to="/retail/setting" :name="$t('cloud.title.setting')" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown
        v-if="myAuthorizationLevel.level <= 100 && subscribing('delivery')"
        :name="$t('cloud.title.service_delivery')" icon="cil-truck" route="/delivery"
      >
        <CSidebarNavItem to="/delivery-management/delivery" :name="$t('cloud.title.history')" />
        <CSidebarNavItem to="/delivery-management/statistics" :name="$t('cloud.title.statistics_delivery')" />
        <CSidebarNavItem to="/delivery-management/freight" :name="$t('cloud.title.freight')" />
        <CSidebarNavItem to="/delivery-management/correspondent" :name="$t('cloud.title.correspondent')" />
        <CSidebarNavItem to="/delivery-management/qrcode" :name="$t('cloud.title.qrcode')" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown
        v-if="myAuthorizationLevel.level <= 100 && subscribing('parking')"
        :name="$t('cloud.title.service_parking')" icon="cil-garage" route="/parking"
      >
        <CSidebarNavItem to="/parking/places" :name="$t('cloud.title.place')" />
        <CSidebarNavItem to="/parking/statistics" :name="$t('cloud.title.statistics')" />
        <CSidebarNavItem to="/parking/map" :name="$t('cloud.title.map')" />
      </CSidebarNavDropdown>

      <CSidebarNavItem
        v-if="myAuthorizationLevel.level <= 100 && subscribing('congestion')"
        :name="$t('cloud.title.service_congestion')" icon="cil-group" to="/congestion/control-zone"
      />

      <CSidebarNavTitle v-if="myAuthorizationLevel.level <= 20">{{$t('cloud.title.system')}}</CSidebarNavTitle>
      <CSidebarNavDropdown
        v-if="myAuthorizationLevel.level <= 20 && org"
        :name="$t('cloud.title.setting')" icon="cil-settings" route="/setup"
      >
        <CSidebarNavItem to="/setup/organization" :name="$t('cloud.title.organization')" />
        <CSidebarNavItem to="/setup/branches" :name="$t('cloud.title.branch')" />
        <CSidebarNavItem to="/setup/users" :name="$t('cloud.title.user')" />
      </CSidebarNavDropdown>
      <CSidebarNavDropdown
        v-if="myAuthorizationLevel.level <= 2"
        :name="$t('cloud.title.install')" icon="cil-pen-nib" route="/install"
      >
        <CSidebarNavItem to="/install/organizations" :name="$t('cloud.title.organization')" />
        <CSidebarNavItem to="/install/branches" :name="$t('cloud.title.branch')" />
        <CSidebarNavItem to="/install/devices" :name="$t('cloud.title.device')" />
        <CSidebarNavItem to="/install/users" :name="$t('cloud.title.user')" />
      </CSidebarNavDropdown>

      <!-- <CSidebarNavItem to="/device-portal" icon="cil-storage" :name="$t('cloud.title.device_portal')" /> -->
      <CSidebarNavItem v-if="myAuthorizationLevel.level < 10" to="/log" :name="$t('cloud.title.log')" icon="cil-notes" />
    </CSidebarNav>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TheSidebar',
  computed: {
    ...mapState([
      'capability',
      'sidebarShow',
      'sidebarMinimize'
    ]),
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    show () {
      return this.sidebarShow 
    },
    minimize () {
      return this.sidebarMinimize 
    },
    org() {
      if (!this.capability) return null;
      return this.capability.organization.id;
    }
  },
  methods: {
    subscribing(service) {
      if (!this.capability) return false;
      if (!this.capability.organization) return false;
      if (!this.capability.organization.subscription) return false;
      const subscription_id = this.capability.organization.subscription.findIndex(subs => {
        if (subs.service_key === service) {
          // 구독 기간 만료 체크
          if (this.$utility.SubscriptionRemainHour(subs) > 0) {
            return true;
          }
        }
        return false;
      });
      if (subscription_id >= 0) return true;
      return false;
    }
  }
}
</script>
